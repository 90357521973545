import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import FilterOrder from './FilterOrder';
import FilterRole from './FilterRole';
import FilterAdmin from './FilterAdmin';
import FilterCustomer from './FilterCustomer';
import FilterRoute from './FilterRoute';
import FilterHppTerm from './FilterHppTerm';
import FilterCity from './FilterCity';
import FilterDistrict from './FilterDistrict';
import FilterVendorTrucking from './FilterVendorTrucking';
import FilterCommodity from './FilterCommodity';
import FilterVendorShip from './FilterVendorShip';
import FilterHppFcl from './FilterHppFcl';
import FilterShippingPrice from './FilterShippingPrice';
import FilterPortPrice from './FilterPortPrice';
import FilterOtherPrice from './FilterOtherPrice';
import FilterShipDeparture from './FilterShipDeparture';
import FilterShipping from './FilterShipping';
import FilterTtb from './FilterTtb';
import FilterBpb from './FilterBpb';
import FilterContainerBelumDoor from './FilterContainerBelumDoor';
import FilterReceivableReport from './FilterReceivableReport';
import FilterReceivableReportDetail from './FilterReceivableReportDetail';
import FilterBillingReport from './FilterBillingReport';
import FilterClaim from './FilterClaim';
import FilterTicket from './FilterTicket';
import FilterLCL from './FilterLCL';
import FilterPort from './FilterPort';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    filterOder: FilterOrder,
    filterRole: FilterRole,
    filterAdmin: FilterAdmin,
    filterCustomer: FilterCustomer,
    filterRoute: FilterRoute,
    filterHppTerm: FilterHppTerm,
    filterCity: FilterCity,
    filterDistrict: FilterDistrict,
    filterVendorTrucking: FilterVendorTrucking,
    filterCommodity: FilterCommodity,
    filterVendorShip: FilterVendorShip,
    filterHppFcl: FilterHppFcl,
    filterShippingPrice: FilterShippingPrice,
    filterPortPrice: FilterPortPrice,
    filterOtherPrice: FilterOtherPrice,
    filterShipDeparture: FilterShipDeparture,
    filterShipping: FilterShipping,
    filterTtb: FilterTtb,
    filterBpb: FilterBpb,
    filterContainerBelumDoor: FilterContainerBelumDoor,
    filterReceivableReport: FilterReceivableReport,
    filterReceivableReportDetail: FilterReceivableReportDetail,
    filterBillingReport: FilterBillingReport,
    filterClaim: FilterClaim,
    filterTicket: FilterTicket,
    filterLCL: FilterLCL,
    filterPort: FilterPort,
  });
export default reducers;
