/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {momentLocalizer, Views, Navigate, Calendar} from 'react-big-calendar';
import moment from 'moment';
// import {StyledCalendar} from './calendar.style';
import {
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
  Divider,
  Grid,
} from '@mui/material';
import {MdNavigateNext, MdNavigateBefore} from 'react-icons/md';
import {BasicDialog} from 'components/Dialog';
import {useShippingManagement} from 'hooks/ShippingManagement';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import BasicTextField from '../BasicTextField';
import {MdCalendarToday} from 'react-icons/md';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  isCalendar,
  showCalendar,
  hideCalendar,
  showDetailCalendar,
  hideDetailCalendar,
} from 'redux/actions';
import {format} from 'date-fns';

const ColoredDateCellWrapper = ({children}) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });
const localizer = momentLocalizer(moment);

const MyToolbar = (props) => {
  const {label, onNavigate} = props;

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{mb: 4}}
    >
      <Typography fontSize={18} fontWeight={500}>
        {label}
      </Typography>
      <Stack direction='row' spacing={2}>
        <IconButton onClick={() => onNavigate(Navigate.PREVIOUS)}>
          <MdNavigateBefore />
        </IconButton>
        <Button
          color='primary'
          size='small'
          onClick={() => onNavigate(Navigate.TODAY)}
        >
          Today
        </Button>
        <IconButton onClick={() => onNavigate(Navigate.NEXT)}>
          <MdNavigateNext />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const MyAgendaEvent = (props) => {
  const {children, event} = props;
  const [isOpenDetail, setIsOpenDetail] = React.useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <BasicDialog
        title='Event Detail'
        isOpenDialog={isOpenDetail}
        onClose={() => {
          setIsOpenDetail(false);
          dispatch(hideDetailCalendar());
          dispatch(hideCalendar());
        }}
        maxWidth='xs'
      >
        <Typography fontSize={16} fontWeight={500}>
          {event.title}
        </Typography>
        <Typography fontSize={10} color='grey'>
          {moment(event.start).format('DD MMM yyy')} -{' '}
          {moment(event.end).format('DD MMM yyy')}
        </Typography>
        <Divider sx={{my: 4}} />
        <Grid container>
          <Grid item sm={5}>
            <Typography fontSize={12}>ETD</Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography fontSize={12}>:</Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography fontSize={12}>
              {moment(event.detail.etd).format('DD-MM-yyyy')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={5}>
            <Typography fontSize={12}>Open Stack</Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography fontSize={12}>:</Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography fontSize={12}>
              {moment.utc(event.detail.open_stack).format('DD-MM-yyyy')}
              {/* {event.detail.open_stack
                ? format(event.detail.open_stack, 'dd-MM-yyyy')
                : '-'} */}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={5}>
            <Typography fontSize={12}>Closing RC</Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography fontSize={12}>:</Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography fontSize={12}>
              {moment(event.detail.closing_rc).format('DD-MM-yyyy')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={5}>
            <Typography fontSize={12}>Closing Fisik</Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography fontSize={12}>:</Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography fontSize={12}>
              {moment(event.detail.closing_fisik).format('DD-MM-yyyy')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={5}>
            <Typography fontSize={12}>Stack Full</Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography fontSize={12}>:</Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography fontSize={12}>
              {moment(event.detail.stack_full).format('DD-MM-yyyy')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={5}>
            <Typography fontSize={12}>ETA</Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography fontSize={12}>:</Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography fontSize={12}>
              {moment(event.detail.eta).format('DD-MM-yyyy')}
            </Typography>
          </Grid>
        </Grid>
      </BasicDialog>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        // sx={{background: 'red'}}
      >
        <Box
          onClick={() => {
            dispatch(showDetailCalendar());
            setIsOpenDetail(true);
          }}
          sx={{
            cursor: 'pointer',
            width: 10,
            height: 10,
            backgroundColor: 'blue',
            borderRadius: '50%',
            color: 'white',
            mx: 2,
          }}
        >
          {/* {event.title} */}
          {/* {children} */}
        </Box>
      </Stack>
    </>
  );
};

const MyEvent = (props) => {
  return (
    <Box
      sx={{
        borderRight: '0.1px solid #808080',
        margin: 0,
        width: '100%',
      }}
      onClick={() => console.log('props :>> ', props)}
    >
      {props.title}
    </Box>
  );
};

export const ShipScheduleInput = ({inputProps, disabled = false, props}) => {
  const {data} = useShippingManagement({limit: 999});
  const [events, setEvents] = React.useState([]);
  const [isOpenShowMore, setIsOpenShowMore] = React.useState(false);
  const [eventsShowMore, setEventsShowMore] = React.useState([]);
  // const [showCalendar, setShowCalendar] = React.useState(false);
  const dispatch = useDispatch();
  const {calendar, detailCalendar} = useSelector(({common}) => common);
  React.useEffect(() => {
    if (data?.status) {
      setEvents(() =>
        data?.data?.rows?.map((item) => ({
          title: `${item.ship?.name} - ${item.cruiser?.name}`,
          allDay: true,
          start: item.etd,
          end: item.etd,
          detail: item,
        })),
      );
    }
  }, [data]);
  return (
    <>
      <BasicDialog
        title='Event Detail'
        isOpenDialog={isOpenShowMore}
        onClose={() => setIsOpenShowMore(false)}
        maxWidth='xs'
      >
        <Box>
          {eventsShowMore.map((item, index) => (
            <Box key={index}>
              <Typography fontSize={16} fontWeight={500}>
                {item.title}
              </Typography>
              <Typography fontSize={10} color='grey'>
                {moment(item.start).format('DD MMM yyy')} -{' '}
                {moment(item.end).format('DD MMM yyy')}
              </Typography>
              <Divider sx={{mt: 2, mb: 4}} />
            </Box>
          ))}
        </Box>
      </BasicDialog>
      <BasicDialog
        isOpenDialog={calendar}
        title='Pilih jadwal kapal'
        onClose={() => {
          dispatch(hideCalendar());
        }}
      >
        <Box sx={{backgroundColor: 'white', p: 1, borderRadius: 4}}>
          <Calendar
            events={events}
            views={[Views.MONTH]}
            step={60}
            showMultiDayTimes
            // defaultDate={inputProps.value}
            components={{
              timeSlotWrapper: ColoredDateCellWrapper,
              toolbar: MyToolbar,
              eventWrapper: MyAgendaEvent,
              // event: MyEvent,
              // dateCellWrapper,
            }}
            onSelectSlot={(e) => {
              if (e?.slots?.[0]) {
                inputProps.onChange(moment(e.slots?.[0]).toLocaleString());
                setTimeout(() => {
                  if (!detailCalendar) {
                    dispatch(hideCalendar());
                  }
                }, [500]);
                // dispatch(hideCalendar());
                // setShowCalendar(false);
              }
            }}
            on
            selectable
            localizer={localizer}
            onShowMore={(events) => {
              setEventsShowMore(events);
              setIsOpenShowMore(true);
            }}
          />
        </Box>
      </BasicDialog>
      <Box
        onClick={() => {
          if (!disabled) {
            dispatch(showCalendar());
          }
        }}
      >
        <BasicTextField
          {...inputProps}
          disabled={disabled}
          endAdornment={
            <MdCalendarToday
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (!disabled) {
                  dispatch(showCalendar());
                }
              }}
            />
          }
        />
      </Box>
    </>
  );
};

ShipScheduleInput.propTypes = {
  inputProps: PropTypes.any,
  date: PropTypes.any,
};

export default ShipScheduleInput;
