import React from 'react';

const List = React.lazy(() => import('./List'));
const Edit = React.lazy(() => import('./Edit'));
const Create = React.lazy(() => import('./Create'));

export const bpbPagesConfigs = [
  {
    path: '/bpb',
    element: <List />,
  },
  {
    path: '/bpb/create',
    element: <Create />,
  },
  {
    path: '/bpb/:id/edit',
    element: <Edit />,
  },
];
