import React from 'react';

const Main = React.lazy(() => import('./Main'));
const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));
const TruckingSummaryByCity = React.lazy(() =>
  import('./Main/Summary/SummaryByCity'),
);
const TruckingSummaryByDistrict = React.lazy(() =>
  import('./Main/Summary/SummaryByDistrict'),
);

export const vendorTruckingManagementPagesConfigs = [
  {
    path: '/vendor-trucking-management',
    element: <Main />,
  },
  {
    path: '/vendor-trucking-management/create',
    element: <Create />,
  },
  {
    path: '/vendor-trucking-management/:id/edit',
    element: <Edit />,
  },
  {
    path: '/vendor-trucking-management/city/:id',
    element: <TruckingSummaryByCity />,
  },
  {
    path: '/vendor-trucking-management/district/:id/:vehicle_type_id',
    element: <TruckingSummaryByDistrict />,
  },
];
